import * as PropTypes from "prop-types";

import WithoutVideo from "./WithoutVideo";
import WithVideo from "./WithVideo";

const Testimonials = function ({ items }) {
  const hasVideos = Boolean(items.filter((i) => !!i.video_id).length);

  return (
    <>
      {hasVideos && (
        <div>
          {items.map((item, index) => {
            return (
              <WithVideo
                key={index}
                citation={item.citation}
                id={item.video_id}
                quote={item.quote}
                source={item.video_source}
              />
            );
          })}
        </div>
      )}

      {!hasVideos && (
        <div
          className={`gap-8 lg:grid ${items.length > 1 ? "grid-cols-2" : ""}`}
        >
          {items.map((item, index) => {
            return (
              <WithoutVideo
                key={index}
                image={item.photo}
                quote={item.quote}
                citation={item.citation}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

Testimonials.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Testimonials;
