import { VimeoPlayer } from "@up/ui";
import * as PropTypes from "prop-types";
import { useIntersectionObserver } from "usehooks-ts";

import RichText from "~/components/RichText";

const WithVideo = function ({ citation, id, quote }) {
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
  });

  return (
    <div ref={ref} className="testimonial-wrapper grid-cols-2 gap-8 lg:grid">
      <div className="responsive-video testimonial-video">
        <VimeoPlayer shouldLoad={isIntersecting} id={id} />
      </div>
      <div className="testimonial rte">
        <blockquote>
          <RichText field={quote} />
        </blockquote>

        <cite>{citation}</cite>
      </div>
    </div>
  );
};

WithVideo.propTypes = {
  citation: PropTypes.string.isRequired, // Prismic Key Text
  id: PropTypes.string.isRequired, // Prismic Key Text
  quote: PropTypes.array.isRequired, // Prismic RichText
  source: PropTypes.string.isRequired, // Prismic Key Text
};

export default WithVideo;
