import Image from "next/image";
import * as PropTypes from "prop-types";

import RichText from "~/components/RichText";

const WithoutVideo = function ({ image, quote, citation }) {
  return (
    <div className="testimonial testimonial-wrapper testimonial--no-video rte">
      {image.url && (
        <Image
          className="avatar"
          src={image.url}
          alt={image.alt || ""}
          loading="lazy"
          width={image.dimensions.width}
          height={image.dimensions.height}
        />
      )}

      <blockquote>
        <RichText field={quote} />
      </blockquote>

      <cite>{citation}</cite>
    </div>
  );
};

WithoutVideo.propTypes = {
  citation: PropTypes.string, // Prismic Key Text
  image: PropTypes.object.isRequired, // Prismic Image
  quote: PropTypes.array.isRequired, // Prismic RichText
};

export default WithoutVideo;
